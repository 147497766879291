var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('span',{staticClass:"font-weight-bolder text-primary"},[_vm._v(_vm._s(_vm.$t('customer')))]),_c('div',{staticClass:"mt-1"},[_c('b-button',{staticClass:"mb-75",attrs:{"to":{
          name: 'quoteInvoices',
          params: { quoteId: this.$route.params.quoteId },
        },"variant":"outline-success","block":""}},[_vm._v(" "+_vm._s(_vm.$t('invoice2'))+" ")]),(_vm.quote.payment_attachment)?_c('b-button',{staticClass:"mb-75",attrs:{"variant":_vm.quote.payment_status === 'payment_rejected'
            ? 'outline-danger'
            : 'outline-secondary',"block":"","target":"_blank","href":_vm.quote.payment_attachment}},[_vm._v(" "+_vm._s(_vm.$t('invoice'))+" ")]):_vm._e(),_c('UploadPayment',{attrs:{"quote":_vm.quote}}),_c('b-button',{staticClass:"mb-75 mt-75",attrs:{"variant":"outline-secondary","block":""},on:{"click":_vm.printInvoice}},[_vm._v(" "+_vm._s(_vm.$t('print'))+" ")])],1),_c('span',{staticClass:"font-weight-bolder text-primary"},[_vm._v(_vm._s(_vm.$t('supplier')))]),_c('div',{staticClass:"mt-1"},[_c('b-button',{staticClass:"mb-75",attrs:{"to":{
          name: 'purchaseOrders',
          params: { quoteId: _vm.$route.params.quoteId },
        },"variant":"outline-success","block":""}},[_vm._v(" "+_vm._s(_vm.$t('invoice2'))+" ")]),_c('b-button',{staticClass:"mb-75",attrs:{"to":{
          name: 'remissions',
          params: { quoteId: this.$route.params.quoteId },
        },"variant":"outline-success","block":""}},[_vm._v(" "+_vm._s(_vm.$t('remission'))+" ")]),_c('b-button',{staticClass:"mb-75",attrs:{"to":{
          name: 'uploadRemission',
          params: { purchaseOrderId: _vm.purchaseOrder.id },
        },"variant":"outline-success","block":""}},[_vm._v(" "+_vm._s(_vm.$t('uploadRemission'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }