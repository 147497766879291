<template>
  <div>
    <b-card>
      <span class="font-weight-bolder text-primary">{{ $t('customer') }}</span>
      <!-- Button: See uploaded document -->
      <div class="mt-1">
        <b-button
          :to="{
            name: 'quoteInvoices',
            params: { quoteId: this.$route.params.quoteId },
          }"
          variant="outline-success"
          class="mb-75"
          block
        >
          {{ $t('invoice2') }}
        </b-button>
        <b-button
          v-if="quote.payment_attachment"
          :variant="
            quote.payment_status === 'payment_rejected'
              ? 'outline-danger'
              : 'outline-secondary'
          "
          class="mb-75"
          block
          target="_blank"
          :href="quote.payment_attachment"
        >
          {{ $t('invoice') }}
        </b-button>
        <!-- <b-button
          :to="{
            name: 'bankAccountData',
            params: { quoteId: quote.id },
          }"
          variant="outline-success"
          class="mb-75"
          block
        >
          {{ $t('payment') }}
        </b-button> -->
        <UploadPayment :quote="quote" />

        <b-button
          variant="outline-secondary"
          class="mb-75 mt-75"
          block
          @click="printInvoice"
        >
          {{ $t('print') }}
        </b-button>
      </div>
      <span class="font-weight-bolder text-primary">{{ $t('supplier') }}</span>
      <div class="mt-1">
        <b-button
          :to="{
            name: 'purchaseOrders',
            params: { quoteId: $route.params.quoteId },
          }"
          variant="outline-success"
          class="mb-75"
          block
        >
          {{ $t('invoice2') }}
        </b-button>
        <b-button
          :to="{
            name: 'remissions',
            params: { quoteId: this.$route.params.quoteId },
          }"
          variant="outline-success"
          class="mb-75"
          block
        >
          {{ $t('remission') }}
        </b-button>
        <b-button
          :to="{
            name: 'uploadRemission',
            params: { purchaseOrderId: purchaseOrder.id },
          }"
          variant="outline-success"
          class="mb-75"
          block
        >
          {{ $t('uploadRemission') }}
        </b-button>
      </div>
      <!-- <b-button
        :to="{ name: 'clientQuote' }"
        variant="outline-secondary"
        class="mb-75"
        block
      >
        {{ $t('edit') }}
      </b-button> -->
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import { computed } from '@vue/composition-api'
import UploadPayment from '../payments/UploadPayment.vue'

export default {
  components: { UploadPayment },
  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(_, { root }) {
    const store = root.$store
    const printInvoice = () => {
      window.print()
    }

    const purchaseOrder = computed(
      () => store.state.purchaseOrders.purchaseOrder,
    )

    return {
      printInvoice,
      purchaseOrder,
    }
  },
}
</script>

<style></style>
