var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(_vm.quote.payment_attachment)?_c('b-button',{staticClass:"mb-75",attrs:{"variant":_vm.quote.payment_status === 'payment_rejected'
        ? 'outline-danger'
        : 'outline-secondary',"block":"","target":"_blank","href":_vm.quote.payment_attachment}},[_vm._v(" "+_vm._s(_vm.$t('invoice'))+" ")]):_vm._e(),_c('UploadPayment'),_c('b-button',{staticClass:"mb-75 mt-75",attrs:{"to":{
      name: 'quoteInvoices',
      params: { quoteId: this.$route.params.quoteId },
    },"variant":"outline-success","block":""}},[_vm._v(" "+_vm._s(_vm.$t('invoice2'))+" ")]),_c('b-button',{staticClass:"mb-75",attrs:{"variant":"outline-secondary","block":""},on:{"click":_vm.printInvoice}},[_vm._v(" "+_vm._s(_vm.$t('print'))+" ")]),(_vm.quote.quote_status === 'bid_approved' && _vm.quote.is_paid)?_c('b-button',{staticClass:"mb-75",attrs:{"to":{
      name: 'remissions',
      params: { quoteId: this.$route.params.quoteId },
    },"variant":"outline-success","block":""}},[_vm._v(" "+_vm._s(_vm.$t('remission'))+" ")]):_vm._e(),_c('PaymentsModal',{attrs:{"id":"payments-modal"}}),_c('b-form-file',{staticStyle:{"display":"none"},attrs:{"id":"paymentFile"},on:{"input":function($event){return _vm.uploadPayment($event)}},model:{value:(_vm.paymentFile),callback:function ($$v) {_vm.paymentFile=$$v},expression:"paymentFile"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }