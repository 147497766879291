<template>
  <b-table
    responsive
    :items="filteredProducts"
    :fields="computedFields"
  >
    <template #cell(is_express)="data">
      <h6 class="h-100 m-0">
        <b-badge
          v-if="quote.is_express"
          :id="`tooltip-express-${data.item.id}`"
          pill
          :variant="data.item.is_express ? 'success' : 'secondary'"
        >{{ data.item.is_express ? $t('express') : $t('ordinary') }}</b-badge>
      </h6>
    </template>
    <template v-slot:[`cell(product_catalog.name)`]="data">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column ml-1">
          <span class="">
            {{ data.value }}
          </span>
          <span
            class="text-muted"
            style="font-size: 12px;"
          >
            {{ data.item.product_catalog.brand }}
          </span>
        </div>
        <span class="d-flex">
          <b-tooltip
            :target="`tooltip-express-${data.item.id}`"
            triggers="hover"
            boundary-padding="10"
          >
            <span>
              Entrega promedio en
              {{ data.item.is_express.is_express ? '24' : '72' }} Hrs.
            </span>
          </b-tooltip>
        </span>
      </div>
    </template>
    <template #cell(supplier)="data">
      <h6 class="h-100 m-0">
        <b-badge
          class="h-100 text-capitalize"
          variant="primary"
        >
          {{ data.item.supplier }}
        </b-badge>
      </h6>
    </template>
    <template #cell(unit_price)="data">
      <!-- S/IVA -->
      $
      {{
        (Number(data.item.unit_price) +
          Number(data.item.unit_commission_price))
          | money
      }}
    </template>
    <template #cell(unit_price_without_commission)="data">
      <!-- S/IVA -->
      $
      {{ data.item.unit_price | money }}
    </template>
    <template #cell(quantity)="data">
      {{ data.item.quantity }}
    </template>
    <template #cell(unit_type)="data">
      <div>
        {{
          $tc(
            unitsFilter(data.item.product_catalog.unit_type),
            data.item.quantity,
          )
        }}
      </div>
    </template>
    <template #cell(total_without_commission)="data">
      ${{ data.value | money }}
    </template>
    <template #cell(total)="data">
      ${{ data.value | money }}
    </template>
    <template #cell(delete)="data">
      <b-button
        v-if="!data.item.is_express"
        size="sm"
        variant="danger"
        class="d-flex custome-button"
        @click="deleteItem(data.item)"
      >
        <feather-icon
          icon="TrashIcon"
          class="custom-margin"
        />
      </b-button>
    </template>
    <template #cell(whatsapp)="data">
      <b-button
        v-if="getPhone(data.item).phone"
        variant="success"
        size="sm"
        :href="`https://wa.me/52${getPhone(data.item).phone}?text=${encodeURIComponent(getPhone(data.item).message)}`"
        target="_blank"
      >
        <feather-icon icon="MessageCircleIcon" />
        WhatsApp
      </b-button>
      <span
        v-else
        class="text-muted"
      >Completa la compra</span>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
    supplierData: {
      type: Array,
      default() {
        return []
      },
    },
    projectData: {
      type: Array,
      default() {
        return []
      },
    },
    quoteData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'is_express',
          label: this.$t('delivery'),
        },
        {
          key: 'product_catalog.name',
          label: this.$t('products'),
        },
        {
          key: 'supplier',
          label: this.$t('supplier'),
        },
        {
          key: 'whatsapp',
          label: this.$t('whatsapp'),
        },
        {
          key: 'quantity',
          label: this.$t('quantity'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'unit_type',
          label: this.$t('unit'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'unit_price',
          label: this.$t('unitPrice'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'total',
          label: this.$t('total'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('app', ['unitsFilter']),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('auth', ['currentUser']),
    filteredProducts() {
      const filtered = this.products.filter(
        product => product.selected_supplier_branch_id && product.active_status,
      )
      return filtered
    },
    computedFields() {
      const fields = [...this.fields]

      // Incluir columna "eliminar" si es express
      if (this.quote.is_express) {
        fields.push({
          key: 'delete',
          label: this.$t('delete'),
        })
      } else {
        // Eliminar columna "eliminar" si no es express
        const deleteIndex = fields.findIndex(field => field.key === 'delete')
        if (deleteIndex !== -1) {
          fields.splice(deleteIndex, 1)
        }
      }

      // Incluir o eliminar columna "Entrega" (is_express)
      const deliveryIndex = fields.findIndex(field => field.key === 'is_express')
      if (this.quote.is_express) {
        // Si la columna "Entrega" no está, la añadimos
        if (deliveryIndex === -1) {
          fields.unshift({
            key: 'is_express',
            label: this.$t('delivery'),
          })
        }
      } else if (deliveryIndex !== -1) {
        // Si no es express, eliminamos la columna "Entrega"
        fields.splice(deliveryIndex, 1)
      }

      return fields
    },
  },

  created() {
    // If view is purchase order and currentUser is auctioneer we replace: (total => total without comission)
    if (
      this.$route.name === 'purchaseOrder'
      && ['auctioneer', 'supplier_admin'].includes(this.currentUser.role_name)
    ) {
      const totalWithoutCommission = {
        key: 'total_without_commission',
        label: this.$t('total'),
        tdClass: 'text-center',
        thClass: 'text-center',
      }
      const unitPriceWithoutCommission = {
        key: 'unit_price_without_commission',
        label: this.$t('unitPrice'),
        tdClass: 'text-center',
        thClass: 'text-center',
      }
      const indexToDelete1 = this.fields.findIndex(f => f.key === 'total')
      const indexToDelete2 = this.fields.findIndex(f => f.key === 'unit_price')
      this.fields[indexToDelete1] = totalWithoutCommission
      this.fields[indexToDelete2] = unitPriceWithoutCommission
      // this.fields.splice(indexToDelete1, 0, elementToInsert)
    }
    if (this.$route.name === 'checkout') {
      const deleteColumn = {
        key: 'delete',
        label: this.$t('delete'),
      }
      this.fields.push(deleteColumn)
    }
  },
  methods: {
    ...mapActions('quotes', ['deleteQuoteItems', 'fetchQuote']),
    async deleteItem(product) {
      const items = [{ quote_item_id: product.id }]
      await this.deleteQuoteItems({
        quoteId: this.quote.id,
        items,
      })
      this.$emit('delete-user')
    },
    getPhone(item) {
      const foundSupplier = this.supplierData.find(s => s.id === item.selected_supplier_branch_id)
      if (foundSupplier) {
        const customerName = this.quoteData.customer_name
        const address = this.projectData.address.formatted_address
        const projectName = this.projectData.name
        const material = item.product_catalog.name
        const ubication = this.projectData.google_maps_url

        const message = `Solicito estatus de pedido YoConstruyo, 
        Soy: "${customerName}" de 
        Dirección: "${address}", 
        Proyecto: "${projectName}" y 
        Material: "${material}"
        Esta es la ubicación: "${ubication}"`

        return {
          phone: foundSupplier.phone,
          message,
        }
      }
      return { phone: null, message: '' }
    },
  },
}
</script>

<style></style>
