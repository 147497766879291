<template>
  <b-card>
    <!-- Button: See uploaded document -->
    <b-button
      v-if="quote.payment_attachment"
      :variant="
        quote.payment_status === 'payment_rejected'
          ? 'outline-danger'
          : 'outline-secondary'
      "
      class="mb-75"
      block
      target="_blank"
      :href="quote.payment_attachment"
    >
      {{ $t('invoice') }}
    </b-button>

    <UploadPayment />

    <b-button
      :to="{
        name: 'quoteInvoices',
        params: { quoteId: this.$route.params.quoteId },
      }"
      variant="outline-success"
      class="mb-75 mt-75"
      block
    >
      {{ $t('invoice2') }}
    </b-button>

    <b-button
      variant="outline-secondary"
      class="mb-75"
      block
      @click="printInvoice"
    >
      {{ $t('print') }}
    </b-button>
    <!-- Button: Remission -->
    <b-button
      v-if="quote.quote_status === 'bid_approved' && quote.is_paid"
      :to="{
        name: 'remissions',
        params: { quoteId: this.$route.params.quoteId },
      }"
      variant="outline-success"
      class="mb-75"
      block
    >
      {{ $t('remission') }}
    </b-button>
    <PaymentsModal id="payments-modal" />
    <b-form-file
      id="paymentFile"
      v-model="paymentFile"
      style="display: none"
      @input="uploadPayment($event)"
    />
  </b-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PaymentsModal from '@/components/payments/PaymentsModal.vue'
import UploadPayment from '@/components/payments/UploadPayment.vue'

export default {
  components: {
    PaymentsModal,
    UploadPayment,
  },
  props: {
    quote: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      paymentFile: null,
      payment: {
        amount: 0,
        payment_attachment: {
          data: null,
        },
      },
    }
  },
  computed: {
    ...mapState(['apiUrl']),
    ...mapGetters('quotes', ['quotePayments']),
  },
  methods: {
    ...mapActions('quotes', ['uploadQuotePayment', 'getQuotePayments']),
    printInvoice() {
      window.print()
    },
  },
}
</script>

<style></style>
